import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { Typography, Box, Card, Grid, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BLOCKCHAIN_GATEWAY_ENDPOINT, SCANNER_BACKEND } from '../global/const';
import { getRelativeTime } from '../global/utils';

const RELOAD_INTERVAL = 10000;
const BLOCK_SCAN_COUNT = 100;
export const NWRL_DECIMALS = 1000000;

const transactionTableColumns = [
  { field: 'transactionCode', headerName: 'Trans Code', width: 320 },
  { field: 'type', headerName: 'Type', width: 100 },
  { field: 'signer', headerName: 'Signer', width: 320 },
  { field: 'relativeTime', headerName: 'Time', width: 120, align: 'right' },
  { field: 'fee', headerName: 'Fee', width: 100 },
]
const blockTableColumns = [
  { field: 'index', headerName: 'Index', width: 120 },
  { field: 'miner', headerName: 'Validator', width: 320 },
  { field: 'relativeTime', headerName: 'Time', width: 120, align: 'right' },
  { field: 'transactionCount', headerName: 'Transactions', width: 120, align: 'right' },
  { field: 'size', headerName: 'Size', width: 120, align: 'right' },
];

const useStyles = makeStyles({
  icon: {
    // marginRight: theme.spacing(2),
  },
  overviewCards: {
    margin: 5,
    padding: 10,
    width: 150,
    justifyContent: 'center',
    textAlign: 'center'
  },
  cardsRow: {
    display: 'flex',
    height: 80,
  },
  filterColumn: {
    margin: 5,
    padding: 10,
    width: 400,
    display: 'flex',
    flexDirection: 'row',
    spacing: 2,
    justifyContent: 'space-between',
    alignItems: 'center'
    // flex: 1,
  }
});

export default function ChainExplorer() {
  const classes = useStyles();
  const [latestBlock, setLatestBlock] = React.useState();
  const [blocks, setBlocks] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);
  const [nodes, setNodes] = React.useState([]);
  const [searchText, setSearchText] = React.useState();
  // const [nonResponsiveNodes, setNonResponsiveNodes] = React.useState([]);
  const [loadingComplete, setLoadingComplete] = React.useState(false);

  const loadNodes = () => {
    axios.get(BLOCKCHAIN_GATEWAY_ENDPOINT + '/get-last-block-index')
    .then((res) => setLatestBlock(res.data))
    .then(() => setLoadingComplete(true))
    
    // axios.get(SCANNER_BACKEND + '/get-unresponsive-nodes')
    // .then((res) => setNonResponsiveNodes(res.data))
  }

  useEffect(async () => {

    let blocks = [];

    let response = await axios.get(BLOCKCHAIN_GATEWAY_ENDPOINT + `/get-archived-blocks?start_index=${latestBlock - BLOCK_SCAN_COUNT + 1}&end_index=${latestBlock}`)
    response = response.data.blocks;
    response = response.reverse();
    console.log('blocks', response)

    const _blocks = [];
    const _transactions = []
    if (latestBlock) {
      for (let block of response) {
        const blockSize = `${(JSON.stringify(block).length / 1024).toFixed(2)} kb`
        const relativeTime = getRelativeTime(block.timestamp);
        _blocks.push({
          index: block.index,
          miner: block.creator_wallet,
          transactionCount: block.text.transactions.length,
          size: blockSize,
          relativeTime: relativeTime,
        });

        const transaction_signs = block.text.transactions;
        
        for (const tx_sign of transaction_signs) {
          console.log('tx_sign', tx_sign)
          const tx = tx_sign.transaction;
          const sign = tx_sign.signatures[0]

          _transactions.push({
            transactionCode: tx.trans_code,
            type: tx.type,
            signer: sign.wallet_address,
            fee: `${parseInt(tx.fee) / NWRL_DECIMALS} ${tx.currency}`,
            relativeTime: getRelativeTime(tx.timestamp),
          })
        }
      }
      setBlocks(_blocks);
      setTransactions(_transactions)
    }
  }, [latestBlock]) 

  useEffect(() => {
    console.log('Loading blocks');
    loadNodes()
    const interval = setInterval(() => {
      console.log('Loading nodes');
      loadNodes()
    }, RELOAD_INTERVAL);
  
    return () => clearInterval(interval);
  }, [])

  const search = () => {
    if (searchText.startsWith('0x')) {
      window.open('https://mainnetgw.newrl.net/get-wallet?wallet_address=' + searchText)
    } else if (isNaN(searchText)) {
      // window.open('https://mainnetgw.newrl.net/get-transaction?transaction_code=' + searchText)
      window.open('/tx/' + searchText)
    } else {
      window.open(`https://mainnetgw.newrl.net/get-archived-blocks?start_index=${searchText}&end_index=${searchText}`)
    }
  }

  return (
    <Box sx={{
      height: '100vh',
      width: '100%',
      '& .node-map-theme--miner': {
        bgcolor: 'green'
      },
      '& .node-map-theme--committee': {
        bgcolor: 'yellowgreen'
      },
    }}>
      <Typography variant='h4' align='center' paddingBottom={2}>Block Explorer</Typography>
      <Grid container>
        <Grid item>
          <Box className={classes.cardsRow}>
            <Card className={classes.overviewCards}>
              <Typography variant="h6">Latest Block</Typography>
              <Typography>{latestBlock}</Typography>
            </Card>
            </Box>
        </Grid>
        <Grid item xs>
          <Box className={classes.cardsRow}>
            <Card className={classes.filterColumn}>
              <Grid container style={{display: 'flex', flexDirection: 'row'}} spacing={2}>
                <Grid item xs>
                  <TextField value={searchText} onChange={(e) => setSearchText(e.target.value)}
                  size="small" fullWidth  label="Search by Block/Txn/Wallet"/>
                  </Grid>
                <Grid item xs={3}>
                  <Button variant='contained' onClick={search}>Search</Button>
                
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Grid>
      </Grid>
      
      <DataGrid
        rows={blocks}
        columns={blockTableColumns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        getRowId={(row) => row.index}
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        onRowDoubleClick={(params) => {
          window.open(BLOCKCHAIN_GATEWAY_ENDPOINT + `/get-archived-blocks?start_index=${params.row.index}&end_index=${params.row.index}`)
        }}
        loading={!loadingComplete}
      />
      <Typography variant='h6' align='center' padding={2}>Latest Transactions</Typography>
      <DataGrid
        rows={transactions}
        columns={transactionTableColumns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        getRowId={(row) => row.transactionCode}
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        onRowDoubleClick={(params) => {
          window.open(`/tx/${params.row.transactionCode}`)
        }}
        loading={!loadingComplete}
      />
    </Box>
  );
}