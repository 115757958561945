export function getRelativeTime(timestamp) {
    const currentTime = Date.now();
    const timeDifference = currentTime - timestamp;
  
    const secondInMillis = 1000;
    const minuteInMillis = secondInMillis * 60;
    const hourInMillis = minuteInMillis * 60;
    const dayInMillis = hourInMillis * 24;
    const weekInMillis = dayInMillis * 7;
    const monthInMillis = dayInMillis * 30;
    const yearInMillis = dayInMillis * 365;
  
    if (timeDifference < minuteInMillis) {
      const secondsAgo = Math.floor(timeDifference / secondInMillis);
      return `${secondsAgo} ${secondsAgo === 1 ? 'second' : 'seconds'} ago`;
    } else if (timeDifference < hourInMillis) {
      const minutesAgo = Math.floor(timeDifference / minuteInMillis);
      return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
    } else if (timeDifference < dayInMillis) {
      const hoursAgo = Math.floor(timeDifference / hourInMillis);
      return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
    } else if (timeDifference < weekInMillis) {
      const daysAgo = Math.floor(timeDifference / dayInMillis);
      return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
    } else if (timeDifference < monthInMillis) {
      const weeksAgo = Math.floor(timeDifference / weekInMillis);
      return `${weeksAgo} ${weeksAgo === 1 ? 'week' : 'weeks'} ago`;
    } else if (timeDifference < yearInMillis) {
      const monthsAgo = Math.floor(timeDifference / monthInMillis);
      return `${monthsAgo} ${monthsAgo === 1 ? 'month' : 'months'} ago`;
    } else {
      const yearsAgo = Math.floor(timeDifference / yearInMillis);
      return `${yearsAgo} ${yearsAgo === 1 ? 'year' : 'years'} ago`;
    }
  }
  