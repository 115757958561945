import * as React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route, useParams } from "react-router-dom";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { Typography, Box, Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BLOCKCHAIN_GATEWAY_ENDPOINT, SCANNER_BACKEND } from '../global/const';

const useStyles = makeStyles({
  icon: {
    // marginRight: theme.spacing(2),
  },
  overviewCards: {
    margin: 5,
    padding: 10,
    width: 150,
    justifyContent: 'center',
    textAlign: 'center'
  },
});

export default function TransactionViewer({match}) {
  let params = useParams();
  
  const classes = useStyles();
  const [transactionCode, setTransactionCode] = React.useState(params.trans_code);
  const [blockIndex, setBlockIndex] = React.useState(0);
  const [transactionData, setTransaction] = React.useState({});
  const [loadingComplete, setLoadingComplete] = React.useState(false);

  useEffect(async () => {
    axios.get(`${BLOCKCHAIN_GATEWAY_ENDPOINT}/get-transaction?transaction_code=${transactionCode}`)
    .then((res) => {
      setBlockIndex(res.data.block_index)
    })
    // .then(() => setLoadingComplete(true))
    
  }, []) 
  
  useEffect(async () => {
    axios.get(BLOCKCHAIN_GATEWAY_ENDPOINT + `/get-archived-blocks?start_index=${blockIndex}&end_index=${blockIndex}`)
    .then((res) => {
      const block = res.data.blocks[0];
      const tx = block?.text?.transactions.find((tx) => tx?.transaction?.trans_code === transactionCode);
      setTransaction(tx)
      console.log(tx)
    })
    .then(() => setLoadingComplete(true))
  }, [blockIndex]) 

  return (
    <Box sx={{
      height: '100vh',
      margin: '20px',
      width: '100%',
      '& .node-map-theme--miner': {
        bgcolor: 'green'
      },
      '& .node-map-theme--committee': {
        bgcolor: 'yellowgreen'
      },
    }}>
      {/* <Typography variant='h4' align='center' paddingBottom={2}>Transaction {transactionCode}</Typography> */}
      {/* <Typography variant='subtitle1' align='center' paddingBottom={2}>Included block {blockIndex}</Typography> */}
      {transactionData?.transaction? <Grid>
        <Typography variant='h6'>Transaction Code: {transactionData?.transaction?.trans_code}</Typography>
        <Typography>Included Block: {blockIndex}</Typography>
        <Typography>Timestamp: {transactionData?.transaction?.timestamp}</Typography>
        <Typography>Type: {transactionData?.transaction?.type}</Typography>
        <Typography>Fee: {transactionData?.transaction?.fee}</Typography>
        {/* <Typography>Signer: {transactionData?.signatures[0]?.wallet_address}</Typography> */}
        <div width="300px">JSON: <pre>{JSON.stringify(transactionData, null, 1)}</pre></div>
      </Grid>
      
      :
      <Typography>Transaction not found</Typography>}
      
      
      
      
      
    </Box>
  );
}