import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from "react-router-dom";

import "./App.css";

// import BlockExplorer from "./screens/BlockExplorer";
import ChainExplorer from "./screens/ChainExplorer";
import NodeMap from "./screens/NodeMap";
import TransactionViewer from "./screens/TransactionViewer";

function App() {
  return (
    <Router>
      <div>
        <nav>
          <div style={{ 
            padding: '20px', 
            display: 'flex', 
            justifyContent: 'space-around', 
            fontSize: '18px',
            color: 'white',
            backgroundColor: '#1d6fa5',
            marginBottom: '10px'
          }}>
            <Link to="/node-map" style={{color: 'white'}}>Node Map</Link>

            <Link to="/block-explorer" style={{color: 'white'}}>Block Explorer</Link>
          </div>
        </nav>

        <Routes>
          <Route path="/" element={<NodeMap />} />
          <Route path="/node-map" element={<NodeMap />} />
          {/* <Route path="/block-explorer" element={<BlockExplorer />} /> */}
          <Route path="/block-explorer" element={<ChainExplorer />} />
          <Route path="/tx/:trans_code" element={<TransactionViewer />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
