import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { Typography, Box } from '@mui/material';
import { BLOCKCHAIN_PORT, SCANNER_BACKEND } from '../global/const';

const RELOAD_INTERVAL = 10000;

const nonResponsiveColumns = [
  { field: 'ipAddress', headerName: 'IP Address', width: 200 },
]
const columns = [
  { field: 'ipAddress', headerName: 'IP Address', width: 120 },
  { field: 'walletAddress', headerName: 'Wallet Address', width: 320 },
  { field: 'lastBlockIndex', headerName: 'Last Block', width: 80 },
  { field: 'lastBlockHash', headerName: 'Last Block Hash', width: 230 },
  { field: 'peerCount', headerName: 'Peer Count', width: 80 },
  { field: 'softwareVersion', headerName: 'Software Version', width: 110 },
  { field: 'expectedMiner', headerName: 'Expected Miner', width: 280 },
];

export default function NodeMap() {
  const [nodes, setNodes] = React.useState([]);
  const [nonResponsiveNodes, setNonResponsiveNodes] = React.useState([]);
  const [loadingComplete, setLoadingComplete] = React.useState(false);

  const loadNodes = () => {
    axios.get(SCANNER_BACKEND + '/get-nodes')
    .then((res) => setNodes(res.data))
    .then(() => setLoadingComplete(true))
    
    axios.get(SCANNER_BACKEND + '/get-unresponsive-nodes')
    .then((res) => setNonResponsiveNodes(res.data))
  }

  useEffect(() => {
    console.log('Loading nodes');
    loadNodes()
    const interval = setInterval(() => {
      console.log('Loading nodes');
      loadNodes()
    }, RELOAD_INTERVAL);
  
    return () => clearInterval(interval);
  }, [])

  return (
    <Box sx={{
      height: '100vh',
      width: '100%',
      '& .node-map-theme--miner': {
        bgcolor: 'green'
      },
      '& .node-map-theme--committee': {
        bgcolor: 'yellowgreen'
      },
    }}>
      <Typography variant='h4' align='center' paddingBottom={2}>Newrl Node Map</Typography>
      <DataGrid
        rows={nodes}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        getRowId={(row) => row.ipAddress}
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        onRowDoubleClick={(params) => {
          window.open(`http://${params.row.ipAddress}:${BLOCKCHAIN_PORT}/docs`)
        }}
        loading={!loadingComplete}
        getRowClassName={(params) => `node-map-theme--${params.row.isMiner ? 'miner' : params.row.isCommitteeMember ? 'committee' : 'normal'}`}
      />
      <Typography variant='h6' align='center' padding={2}>Unresponsive Nodes</Typography>
      <DataGrid
        rows={nonResponsiveNodes}
        columns={nonResponsiveColumns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        getRowId={(row) => row.ipAddress}
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        onRowDoubleClick={(params) => {
          window.open(`http://${params.row.ipAddress}:${BLOCKCHAIN_PORT}/docs`)
        }}
        loading={!loadingComplete}
        getRowClassName={(params) => `node-map-theme--${params.row.isMiner ? 'miner' : params.row.isCommitteeMember ? 'committee' : 'normal'}`}
      />
    </Box>
  );
}